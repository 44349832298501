export const useScrollToTop = async (href?: string) => {
  if (process.client) {
    const router = useRouter();

    if (href && href !== router.currentRoute.value.path) {
      await router.push(useLocalePrefixForHref('/'));
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};